<template>
  <div>
    <navigation msg="岗位详情"></navigation>
    <div class="detailUserInfo">
      <div >
      <div class="detailUserInfoTitle">
        <div class="postName">{{detail.job_nickname}}</div>
        <div class="salary">{{detail.salary_min}}- {{detail.salary_max}}</div>
      </div>
      </div>
      <div class="tabList">
        <div class="tabItem" v-if="detail.years_working">{{getYearsWork(detail.years_working)}}</div>
        <div class="tabItem" v-if="detail.education_background">{{detail.education_background}}</div>
        <div class="tabItem">{{detail.recruit_number}}人</div>
        <div class="tabItem2">{{getStatus(detail.status)}}</div>
        <div class="tabItem3">{{getStatus1(detail.post_audit)}}</div>
      </div>
      <div class="postLevel">{{detail.job}}</div>
      <div class="price">{{detail.rewards}}元/人</div>
      <div class="postLevel">工作地址：{{detail.company_address + detail.detailed_address}}</div>
      <div class="title" >职位信息</div>
      <div class="content"> 
         {{detail.post_desc ? detail.post_desc : "暂无"}}
      </div>
      <div class="title">服务信息</div>
      <div class="btns"> 
        <div class="btn" v-if="detail.expire == 1">招聘时间：30天</div>
        <div class="btn" v-if="detail.expire == 2">招聘时间：60天</div>
        <div class="btn" v-if="detail.guarantee_time == 1">交付成功节点：入职过保7天</div>
        <div class="btn" v-if="detail.guarantee_time == 2">交付成功节点：入职过保14天</div>
        <div class="btn" v-if="detail.guarantee_time == 3">交付成功节点：入职过保30天</div>
        <div class="btn" v-if="detail.rewards">悬赏金额：{{detail.rewards}}</div>
        <div class="btn" v-if="detail.backgroud_report == 1">是否需要背调：是</div>
      </div>
      <div class="title">优先考虑范围</div>
      <div class="content">{{detail.priority_area}}</div>
      <div class="title">薪资福利</div>
      <div class="btns"> 
        <div class="btn">工资：{{detail.salary_constitute}}</div>
        <div class="btn" v-if="detail.insurance_fund">五险比例： 工资 *  {{detail.insurance_fund.guaranteeValue1 }}%</div>
        <div class="btn">一金比例：工资 * {{detail.insurance_fund.guaranteeValue2}}</div>
        <div class="btn" v-if="detail.contract_time">合同签署年限：{{detail.contract_time == 0 ? "3年以上" : detail.contract_time + "年"}}</div>
        <div class="btn" v-if="detail.probation_period">试用期：{{detail.probation_period == 0 ? "无试用期" : detail.probation_period}}个月</div>
        <div class="btn" v-if="detail.probation_rebate">试用期薪资：{{detail.probation_rebate}}%</div>
        <div class="btn" v-if="detail.other_welfare_programs">其他福利：{{detail.other_welfare_programs}}</div>
      </div>
      <div style="height:1.5rem"></div>
      <div class="detailBottom">
        <div class="bottomBtn1" @click="handledelPost">删除</div>
        <div class="bottomBtn1" @click="editPost">编辑</div>
        <div class="bottomBtn2" @click="closePost" v-if="detail.status == 2">关闭职位</div>
        <div class="bottomBtn2" @click="openPost" v-if="detail.status == 3">重新发布</div>
        <div class="bottomBtn2" @click="removePush " v-if="detail.post_audit == 30">重新提交</div>
        <div class="bottomBtn2" @click="handletoPay" v-if="detail.status == 1">去支付</div>
      </div>
    </div>
    
  </div>
</template>
<script>
import { mapActions ,mapState} from 'vuex';
import { Dialog } from "vant";
export default {
  data(){
    return {
      detail:{},
    }
  },
  mounted(){
    this.getDetail();
  },
   computed: {
    // 引入vuex中存储的数据
    ...mapState({
      userId: (state) => state.userId,
      conpanyId: (state) => state.conpanyId,
    }),
  },
  methods:{
    ...mapActions(["delPost"]),
    removePush(){
      Dialog.alert({
        title: '确认重新提交审核该岗位吗？',
        message: '确认重新提交审核该岗位吗？',
        showCancelButton:true
      }).then(() => {
        this.$http
        .post("/firm/v1/record/resetJob",{
          reqType: "record", //请求类型
          job_id: this.$route.query.id //岗位id31
        }).then((res)=>{
          res = JSON.parse(res.data);
          this.$message.success("岗位提交审核成功！");
          this.getDetail();
        })
      })
      
    },
    handletoPay(){
      if (this.detail.post_audit == 10 || this.detail.post_audit == 30) {
        this.$message({
          message: "该岗位还未通过审核",
          type: "warning",
        });
        return;
      }
       Dialog.alert({
        title: '确认支付该岗位吗？',
        message: '确认支付该岗位吗？',
        showCancelButton:true
      }).then(() => {
        this.$http
        .post("/firm/v1/record/jobPay",{
          reqType: "record",
          post_id: this.$route.query.id,
          e_id:  localStorage.getItem("conpanyId"),
          m_id:  localStorage.getItem("userId"),
        }).then((res)=>{
          res = JSON.parse(res.data);
          this.$message.success("岗位支付成功！");
          this.getDetail();
        })
      })

    },
    closePost(){
      Dialog.alert({
        message: '确认关闭该岗位吗？',
        showCancelButton:true
      }).then(() => {
        this.$http
        .post("/firm/v1/record/closeJob",{
          reqType: "record", //请求类型
          job_id:  this.$route.query.id, //岗位id31
          status: 3//状态  1未支付待发布  2已支付正在悬赏 打开  3关闭
        }).then((res)=>{
          res = JSON.parse(res.data);
          this.$message.success("岗位关闭成功！");
          this.getDetail();
        })
      })
      
    },
    openPost(){
      Dialog.alert({
        message: '确认重新发布该岗位吗？',
        showCancelButton:true
      }).then(() => {
        this.$http
        .post("/firm/v1/record/resetPostJobs",{
          reqType: "record", //请求类型
          m_id: localStorage.getItem("userId"), //用户id
          company_id: localStorage.getItem("conpanyId"), //公司id
          cache_key: "", 
          release_id: this.$route.query.id  //发布id
        }).then((res)=>{
          res = JSON.parse(res.data);
          this.$message.success("岗位重新发布成功！");
          setTimeout(()=>{
            this.$router.go(-1)
          },500)
        })
      })
    },
    getDetail() {
      this.$http
        .post("/firm/v1/record/postDetails", {
          reqType: "record",
          sub_id: this.$route.query.id, //岗位id
          is_selected: "", //是否被选中1 -是 0-否  null为全部
        })
        .then((res) => {
          res = JSON.parse(res.data);
          console.log(res)
          if(res.data.insurance_fund){
            res.data.insurance_fund = JSON.parse(res.data.insurance_fund)
          }
          this.detail = res.data;
        
        });
    },
    getYearsWork(num) {
      let year;
      switch (Number(num)) {
        case 0:
          year = "不限";
          break;
        case 1:
          year = "1年以内";
          break;
        case 2:
          year = "1-3年";
          break;
        case 3:
          year = "3-5年";
          break;
        case 4:
          year = "5-10年";
          break;
        case 5:
          year = "10年以上";
          break;
      }
      return year;
    },
    getStatus1(status) {
      let str;
      switch (status) {
        case 10:
          str = "未审核";
          break;
        case 20:
          str = "审核通过";
          break;
        case 30:
          str = "未通过";
          break;
      }
      return str;
    },
    // 岗位状态
    getStatus(status) {
      let str;
      switch (status) {
        case 0:
          str = "待发布";
          break;
        case 1:
          str = "待支付";
          break;
        case 2:
          str = "招聘中";
          break;
        case 3:
          str = "关闭";
          break;
      }
      return str;
    },
    handledelPost(){
      Dialog.alert({
        message: '确认删除该岗位吗？',
        showCancelButton:true
      }).then(() => {
        this.$http.post("/firm/v1/record/delPost" ,{
          reqType: "record", //请求类型
          id: this.detail.id, //岗位id
        }).then((res)=>{
            res = JSON.parse(res.data);
            if(res.code == 0) {
              this.$message.success("岗位删除成功！");
              setTimeout(()=>{
                this.$router.go(-1)
              },500)
            }
        })
      });
    },
    editPost(){
      this.$store.commit("SET_POST",this.detail)
      this.$store.commit("SET_POST_STATUS", "edit");
      this.$store.commit("SET_POST_ID", this.detail.id);
      this.$router.push("/step1")
    }
  }
}
</script>
<style scoped>
.detailBottom{
  width:100%;
  height:1.5rem;
  background-color:#FFF;
  position: fixed;
  bottom:0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.bottomBtn1{
  padding:0.2rem 0.3rem;
  background: #FFFFFF;
  border-radius: 0.08rem;
  border: 0.02rem solid #FF3B24;
  font-size: 0.25rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FF4811;
  margin-right:0.2rem;
}
.bottomBtn2{
  flex:1;
  padding:0.2rem 0.3rem;
  background: #FF3B24;
  text-align: center;
  border-radius: 0.08rem;
  border: 0.02rem solid #FF3B24;
  font-size: 0.25rem;
  color:#FFF;
  margin-right:0.5rem;
}
.btns{
  display: flex;
  flex-wrap: wrap;
  margin-top:0.3rem;
}
.btn{
  background: #F4F4F4;
  border-radius: 0.01rem;
  padding:0.1rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #454E62;
  margin-right:0.2rem;
  margin-bottom:0.2rem;
}
.title{
  font-size: 0.33rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  margin-top:0.4rem;
}
.content{
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #454E62;
  margin:0.2rem 0;
}
.postLevel{
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #454E62;
  margin:0.2rem 0;
  font-size: 0.28rem;
}
.price{
  font-size: 0.5rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FF3800;
  border-bottom:0.01rem solid #EFEFEF;
  padding-bottom:0.2rem;
}
.tabList{
  display: flex;
  flex-wrap: wrap;
  margin-top:0.2rem;
}
.tabItem{
  padding:0.1rem 0.2rem;
  background: #F4F4F4;
  margin-right:0.2rem;
}
.tabItem1{
  padding:0.1rem 0.2rem;
  margin-right:0.1rem;
  background: #FFFFFF;
  border-radius: 0.05rem;
  border: 0.01rem solid #FF2735;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FF2735;
}
.tabItem2{
  padding:0.1rem 0.2rem;
  margin-right:0.1rem;
  background: #FFFFFF;
  border-radius: 0.05rem;
  border: 0.01rem solid #5FE075 ;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #5FE075;
}
.tabItem3{
  padding:0.1rem 0.2rem;
  margin-right:0.1rem;
  background: #FFFFFF;
  border-radius: 0.05rem;
  border: 0.01rem solid #007EFF ;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #007EFF;
}
.detailUserInfo{
  padding:0.1rem 0.2rem;
  background: #fff;
}
.postName{
  font-size: 0.4rem;
}
.detailUserInfoTitle{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>